//import react section
import React  from 'react'
import { useDispatch } from 'react-redux'
import { withRouter } from 'react-router-dom'
/** Material UI imports section */
import {
	Badge,
	Button,
	Chip,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
	withStyles,
} from '@material-ui/core'
import { AddBox, AddCircle, Delete, Public, Send } from '@material-ui/icons'
import { Message } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'
//import common components

import DataTable from '../../../common/DataTable'
import LoadingPage from '../../../common/LoadingPage'
import GeneralData from './GeneralData'
import HeaderComponent from './HeaderComponent'
import InternationalComponent from './InternationalComponent'
import NationalComponent from './NationalComponent'
import PreviousOriginComponent from './PreviousOriginComponent'
//Import store section
import yourlogohere from '../../../../resources/images/yourlogohere.png'
import { containerOptions } from '../../../../store/helpers/SelectOptions'
import { deleteContainer, deleteMaterial } from '../../../../store/loadAlerts/LoadAlertAction'
import {
	AvailableOperationsConfiguration,
	loadAlertItemStatus,
	onMessageStatusDialog,
	onTitleStatusDialog,
} from '../../../../store/helpers/LoadAlertHelper'
import DialogOperation from '../../../sustainability2/components/DialogOperation'
import AddCommonItem from '../../../common/AddCommonItem'

const DesktopView = (props) => {
	const dispatch = useDispatch()

	//array props section
	const { userRolesByCompany } = props

	//functions props section
	const {
		changeLoadAlertStatus,
		onLateralPanelConfig,
		onUpdateRequest,
		addPreviousOriging,
		updateLoadAlertItemProperty,
		onSendNotificationToSupplier,
		deleteAnnexesFiles,
		deleteInvoiceFiles,
		deleteInspectionFiles,
		deletePreviousFiles,
		onModalConfirmLoadAlert,
		setOpenDialog,
		onModalConfirmLoadAlertDelete,
		onModalConfirmLoadAlertFromItem,
		onOpenPanelHistoryMesages,
		onConfirmPasswordConfigModalForCreateTrackingGuide,
		onSyncInformationToSit,
		closeDialogOperation,
		onOpenConfirmDialog,
		onClosedDialog,
		onChangeLoadAlertStatus
	} = props

	//bool props section
	const {
		canbeUpdateSupplierInternational,
		canbeUpdateSupplierNacional,
		canbeUpdateSupplierPreviousOrigin,
		isLoading,
		canBeEditInternalUserData,
		openAvailableOperationsDialog,
		isSaving,
		dialogOperationModal,
	} = props

	//string props section
	const { supplierLogo, urlImageSupplier, tab, setTab } = props
	//object
	const { translate, params, loadAlert, supplier, previousOriginData, nacionalData, internacionalData, userProfile, openDialogConfirm } = props

	if (isLoading) return <LoadingPage />

	let canBeUpdateInformationInternational =
		canBeEditInternalUserData ||
		(canbeUpdateSupplierInternational && internacionalData.statusEnum === loadAlertItemStatus.Draft)
	let canBeUpdateInformationNacional =
		canBeEditInternalUserData ||
		(canbeUpdateSupplierNacional && nacionalData.statusEnum === loadAlertItemStatus.Draft)
	let canBeUpdateInformationPreviousOrigin =
		canBeEditInternalUserData ||
		(canbeUpdateSupplierPreviousOrigin && previousOriginData.statusEnum === loadAlertItemStatus.Draft)

	const onRendercustomCell = (dataSource, item) => {
		switch (dataSource) {
			case '': {
				return (
					<IconButton
						size='small'
						onClick={() => dispatch(deleteMaterial({ token: params.token, materialToken: item.token }))}
					>
						<Delete fontSize='small' />
					</IconButton>
				)
			}
			case 'optionContainer': {
				return (
					(canBeUpdateInformationInternational ||
						canBeUpdateInformationNacional ||
						canBeUpdateInformationPreviousOrigin) && (
						<IconButton
							size='small'
							onClick={() =>
								dispatch(deleteContainer({ token: params.token, materialToken: item.token }))
							}
						>
							<Delete fontSize='small' />
						</IconButton>
					)
				)
			}
			case 'typeContainer': {
				const { typeContainer } = item
				return containerOptions.filter((x) => x.value === typeContainer)[0]
					? containerOptions.filter((x) => x.value === typeContainer)[0].label
					: ''
			}
			default:
				return ''
		}
	}

	const onRenderButtonsFromItems = (item, supplierDescription) => {
		return (
			<>
				{(canbeUpdateSupplierInternational ||
					canbeUpdateSupplierNacional ||
					canbeUpdateSupplierPreviousOrigin) &&
					item &&
					item.statusEnum === loadAlertItemStatus.Draft && (
						<Chip
							label={
								<Translate
									id='loadAlerts.sentDataToReview'
									data={{ supplierType: supplierDescription }}
								/>
							}
							// style={{ borderRadius: 0, width: '300px', height: '45px', gap: '10px' }}
							// color={tab === '3' ? 'primary' : 'inherit'}
							color='primary'
							// style={{ backgroundColor:"blue !important" }}
							variant='outlined'
							icon={<Send />}
							onClick={() =>
								onModalConfirmLoadAlertFromItem(
									item,
									loadAlertItemStatus.ReviewInformation,
									supplierDescription
								)
							}
						/>
					)}
				{canBeEditInternalUserData && item && item.statusEnum === loadAlertItemStatus.ReviewInformation && (
					<>
						<Chip
							label={
								<Translate id='loadAlerts.acceptedItem' data={{ supplierType: supplierDescription }} />
							}
							style={{ color: 'blue !important' }}
							// color={tab === '3' ? 'primary' : 'inherit'}
							// color='secondary'
							// icon={<Send />}
							variant='outlined'
							onClick={() =>
								onModalConfirmLoadAlertFromItem(
									item,
									loadAlertItemStatus.AcceptedInformation,
									supplierDescription
								)
							}
						/>
						<Chip
							label={
								<Translate id='loadAlerts.rejectedItem' data={{ supplierType: supplierDescription }} />
							}
							// style={{ borderRadius: 0, width: '300px', height: '45px', gap: '10px' }}
							// color={tab === '3' ? 'primary' : 'inherit'}
							color='secondary'
							// icon={<Send />}
							onClick={() =>
								onModalConfirmLoadAlertFromItem(item, loadAlertItemStatus.Draft, supplierDescription)
							}
						/>
					</>
				)}

				{item && (
					<Badge badgeContent={item.loadAlertItemsHistories.length} color='primary'>
						<IconButton onClick={() => onOpenPanelHistoryMesages(item.loadAlertItemsHistories)}>
							<Message />
						</IconButton>
					</Badge>
				)}
			</>
		)
	}

	let allContainers = loadAlert.containers ? loadAlert.containers : []

	let isVisebleColumnCode = allContainers.some(
		(x) => x.typeContainer === 'ContainerConsolidated' || x.typeContainer === 'ContainerAereo'
	)

	return (
		<Paper style={{ padding: '10px' }}>
			<HeaderComponent
				//functions
				closeDialogOperation={closeDialogOperation}
				//object props section
				loadAlert={loadAlert}
				//bool
				supplierLogo={supplierLogo}
				changeLoadAlertStatus={changeLoadAlertStatus}
				urlImageSupplier={urlImageSupplier}
				supplier={supplier}
				yourlogohere={yourlogohere}
				userRolesByCompany={userRolesByCompany}
				openAvailableOperationsDialog={openAvailableOperationsDialog}
				setOpenDialog={setOpenDialog}
				onModalConfirmLoadAlert={onModalConfirmLoadAlert}
				onModalConfirmLoadAlertDelete={onModalConfirmLoadAlertDelete}
				tab={tab}
				userProfile={userProfile}
			/>
			<Divider />
			{
				<List>
					<GeneralData
						//object props section
						loadAlert={loadAlert}
						//functions props section
						onSyncInformationToSit={onSyncInformationToSit}
						onUpdateRequest={onUpdateRequest}
						addPreviousOriging={addPreviousOriging}
						canBeEditData={canBeUpdateInformationInternational}
						onOpenPanelHistoryMesages={onOpenPanelHistoryMesages}
						//bool props section
						isSaving={isSaving}
					/>
					<div style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}>
						<div style={{ display: 'flex', gap: '15px' }}>
							{(canbeUpdateSupplierInternational || canBeEditInternalUserData) && (
								<Chip
									label={<Translate id='loadAlerts.foreignSuppluier' />}
									style={{ borderRadius: 0, width: '300px', height: '45px', gap: '10px' }}
									color={tab === '1' ? 'primary' : 'inherit'}
									icon={<Public />}
									onClick={() => setTab('1')}
								/>
							)}
							{(canbeUpdateSupplierNacional || canBeEditInternalUserData) && (
								<Chip
									label={<Translate id='loadAlerts.nationalSuppluier' />}
									style={{ borderRadius: 0, width: '300px', height: '45px', gap: '10px' }}
									color={tab === '2' ? 'primary' : 'inherit'}
									icon={<Public />}
									onClick={() => setTab('2')}
								/>
							)}
							{previousOriginData && (canbeUpdateSupplierPreviousOrigin || canBeEditInternalUserData) && (
								<Chip
									label={<Translate id='loadAlerts.previousOriginSupplier' />}
									style={{ borderRadius: 0, width: '300px', height: '45px', gap: '10px' }}
									color={tab === '3' ? 'primary' : 'inherit'}
									icon={<Public />}
									onClick={() => setTab('3')}
								/>
							)}
						</div>
						<div style={{ display: 'flex' }}>
							{tab === '1' && onRenderButtonsFromItems(internacionalData, 'Internacional')}
							{tab === '2' && onRenderButtonsFromItems(nacionalData, 'Nacional')}
							{tab === '3' && onRenderButtonsFromItems(previousOriginData, 'Previo Origen')}
						</div>
					</div>
				</List>
			}
			{/* {tab === '1' && (
				<List>
					<ListItem>
						<ListItemText
							primary={
								<div style={{ display: 'flex', gap: '15px' }}>
									<Flight />
									<Typography component='span' variant='body2' color='textSecondary'>
										<strong>
											{' '}
											<Translate id='loadAlerts.shipment' />
										</strong>
									</Typography>
								</div>
							}
						/>
					</ListItem>
				</List>
			)} */}
			{(canbeUpdateSupplierInternational || canBeEditInternalUserData) && (
				<InternationalComponent
					//string props section
					tabSelected={tab}
					//object props
					internacionalData={internacionalData}
					loadAlert={loadAlert}
					//functions
					updateLoadAlertItemProperty={updateLoadAlertItemProperty}
					onSendNotificationToSupplier={onSendNotificationToSupplier}
					onLateralPanelConfig={onLateralPanelConfig}
					deleteAnnexesFiles={deleteAnnexesFiles}
					deleteInvoiceFiles={deleteInvoiceFiles}
					onConfirmPasswordConfigModalForCreateTrackingGuide={
						onConfirmPasswordConfigModalForCreateTrackingGuide
					}
					//Array
					userRolesByCompany={userRolesByCompany}
					//Bool props section
					canBeEditData={canBeUpdateInformationInternational}
					canBeEditInternalUserData={canBeEditInternalUserData}
					deleteInspectionFiles={deleteInspectionFiles}
					isSaving={isSaving}
				/>
			)}
			{(canbeUpdateSupplierNacional || canBeEditInternalUserData) && (
				<NationalComponent
					//string props section
					tabSelected={tab}
					//object props
					nacionalData={nacionalData}
					//functions
					updateLoadAlertItemProperty={updateLoadAlertItemProperty}
					onSendNotificationToSupplier={onSendNotificationToSupplier}
					onLateralPanelConfig={onLateralPanelConfig}
					deleteAnnexesFiles={deleteAnnexesFiles}
					deleteInvoiceFiles={deleteInvoiceFiles}
					//bool props section
					canBeEditData={canBeUpdateInformationNacional}
					canBeEditInternalUserData={canBeEditInternalUserData}
					deleteInspectionFiles={deleteInspectionFiles}
					isSaving={isSaving}

				/>
			)}
			{(canbeUpdateSupplierPreviousOrigin || canBeEditInternalUserData) && (
				<PreviousOriginComponent
					//string props section
					tabSelected={tab}
					//object props
					previousOriginData={previousOriginData}
					//functions
					updateLoadAlertItemProperty={updateLoadAlertItemProperty}
					onSendNotificationToSupplier={onSendNotificationToSupplier}
					onLateralPanelConfig={onLateralPanelConfig}
					deleteAnnexesFiles={deleteAnnexesFiles}
					deleteInvoiceFiles={deleteInvoiceFiles}
					deletePreviousFiles={deletePreviousFiles}
					//bool props section
					canBeEditData={canBeUpdateInformationPreviousOrigin}
					canBeEditInternalUserData={canBeEditInternalUserData}
					deleteInspectionFiles={deleteInspectionFiles}
					isSaving={isSaving}

				/>
			)}

			<List>
				<ListItem>
					<ListItemText
						primary={
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', gap: '10px' }}>
									<AddBox />
									<Typography
										component='span'
										variant='body2'
										color='textSecondary'
										style={{ whiteSpace: 'nowrap' }}
									>
										<strong>
											{' '}
											<Translate id='loadAlerts.containersBoxes' />
										</strong>
									</Typography>
								</div>
								{(canBeUpdateInformationInternational ||
									canBeUpdateInformationNacional ||
									canBeUpdateInformationPreviousOrigin) && (
									<Button onClick={() => onLateralPanelConfig('AddLoadAlert')}>
										<Typography component='span' variant='body2' style={{ marginRight: '8px' }}>
											Agregar contenedores
										</Typography>
										<AddCircle style={{ color: '#4377C4' }} />
									</Button>
								)}
							</div>
						}
					/>
				</ListItem>
				<DataTable
					data={allContainers}
					configuration={TableConfiguration(isVisebleColumnCode)}
					isIndex
					onRenderCellItem={onRendercustomCell}
				/>
			</List>
			<List>
				<ListItem>
					<ListItemText
						primary={
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
								<div style={{ display: 'flex', gap: '15px' }}>
									<AddBox />
									<Typography
										component='span'
										variant='body2'
										color='textSecondary'
										style={{ whiteSpace: 'nowrap' }}
									>
										<strong>
											{' '}
											<Translate id='loadAlerts.material' />
										</strong>
									</Typography>
								</div>
								{false && (
									<Button onClick={() => onLateralPanelConfig('AddMaterial')}>
										<Typography component='span' variant='body2' style={{ marginRight: '8px' }}>
											Agregar material
										</Typography>
										<AddCircle style={{ color: '#4377C4' }} />
									</Button>
								)}
							</div>
						}
					/>
				</ListItem>
				<DataTable
					data={loadAlert.materials ? loadAlert.materials : []}
					configuration={TableMaterialConfiguration()}
					isIndex
					onRenderCellItem={onRendercustomCell}
				/>

				{dialogOperationModal && (
					<DialogOperation
						open={dialogOperationModal}
						onClose={closeDialogOperation}
						options={AvailableOperationsConfiguration(
							loadAlert ? loadAlert.status : 0,
							onOpenConfirmDialog,
							canbeUpdateSupplierInternational,
							canBeEditInternalUserData,
							canBeEditInternalUserData,
							canBeEditInternalUserData
						)}
					/>
				)}

				{openDialogConfirm.open && (
					<AddCommonItem
						title={<Translate id={onTitleStatusDialog(openDialogConfirm.status)} />}
						message={translate(onMessageStatusDialog(openDialogConfirm.status))}
						open={openDialogConfirm.open}
						onClose={onClosedDialog}
						onSaveItem={onChangeLoadAlertStatus}
						isSaving={isSaving}
						saveButtonTranslate={'common.confirm'}
						isMessage={false}
						isTextArea={true}
					/>
				)}
			</List>
		</Paper>
	)
}
function TableConfiguration(isVisebleColumnCode) {
	let columns = [
		{
			header: <Translate id='loadAlerts.unitType' />,
			dataSource: 'typeContainer',
			isSortable: true,
		},
	]

	if (isVisebleColumnCode) {
		columns.push({
			header: <Translate id='loadAlerts.type' />,
			dataSource: 'code',
			isSortable: true,
		})
	}
	columns.push({
		header: <Translate id='loadAlerts.quantity' />,
		dataSource: 'quantity',
	})
	columns.push({
		header: <Translate id='common.options' />,
		dataSource: 'optionContainer',
	})

	return { columns }
}
function TableMaterialConfiguration() {
	let columns = [
		{
			header: "Orden de compra",
			dataSource: 'purchaseOrder',
			isSortable: true,
		},
		{
			header: <Translate id='loadAlerts.partNumberCode' />,
			dataSource: 'partNumberCode',
			isSortable: true,
		},
		{
			header: <Translate id='loadAlerts.partNumberDescription' />,
			dataSource: 'partNumberDescription',
			isSortable: true,
		},
		{
			header: <Translate id='loadAlerts.measurementUnit' />,
			dataSource: 'measurementUnit',
			isSortable: true,
		},
		{
			header: <Translate id='loadAlerts.quantity' />,
			dataSource: 'quantity',
		},
		// {
		// 	header: <Translate id='common.options' />,
		// 	dataSource: '',
		// },
	]
	return { columns }
}

/** Resources import section */
const useStyle = (theme) => ({})

export default withRouter(withStyles(useStyle, { withTheme: true })(DesktopView))
