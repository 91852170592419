/** Import ReactJS */
import React, { useEffect, useState } from 'react'

import { withRouter } from 'react-router-dom'
/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'
/**Import section components */

import {
	Divider,
	List,
	ListItem,
	ListItemText,
	TextField,
	Typography,
	Grid,
	ListItemIcon,
	Button,
} from '@material-ui/core'
import { Translate } from 'react-localize-redux'
import ReactSelect from 'react-select'
import { carrierOptions, incotermOptions, trackWithOptions } from '../../../../store/helpers/SelectOptions'
import ListDocumentsComponent from './ListDocumentsComponent'
import SelectSupplierComponent from './SelectSupplierComponent'
import { AddAlert } from '@material-ui/icons'

/** Resources import section */
const useStyle = (theme) => ({})

const InternationalComponent = (props) => {
	//function props section
	const {
		updateLoadAlertItemProperty,
		onLateralPanelConfig,
		onSendNotificationToSupplier,
		deleteAnnexesFiles,
		deleteInvoiceFiles,
		deleteInspectionFiles,
		onConfirmPasswordConfigModalForCreateTrackingGuide,
		loadAlert,
	} = props
	//object props section
	const { internacionalData } = props
	//tab props section
	const { tabSelected } = props
	//Array props section
	// const { userRolesByCompany } = props
	//bool props section
	const { canBeEditData, canBeEditInternalUserData, isSaving } = props
	if (!internacionalData) return null
	const [operationType, setOperationType] = useState('')

	useEffect(() => {
		if (internacionalData && internacionalData.incoterm) {
			setOperationType(internacionalData.incoterm)
		}
	}, [internacionalData])

	const onUpdatePropertyCommon = (property, value) => {
		let objectProperties = {
			token: internacionalData.token,
		}
		objectProperties[property] = value
		updateLoadAlertItemProperty(objectProperties)
	}
	const selectedOption = incotermOptions.find((option) => option.value === operationType)
	const selectedCarrierOption = carrierOptions.find((option) => option.value === internacionalData.carrierCode)
	const selectedTrackWithOption = trackWithOptions.find((option) => option.value === internacionalData.trackWith)

	let canBeConectWithShipsGo = loadAlert && loadAlert.can_be_conect_with_ships_go

	return (
		<>
			{tabSelected === '1' && (
				<List
				// style={{ backgroundColor: 'rgb(250, 250, 250)' }}
				>
					<SelectSupplierComponent
						title={<Translate id='loadAlerts.transportLine' />}
						updateLoadAlertItemProperty={updateLoadAlertItemProperty}
						item={internacionalData}
						onSendNotificationToSupplier={onSendNotificationToSupplier}
						canBeEditInternalUserData={canBeEditInternalUserData}
					/>
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.referenceNumber' />:
													</strong>
												</Typography>
												{internacionalData && (
													// IsSelectorNullOrEmpty(internacionalData.referenceNumber) && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon(
																'ReferenceNumber',
																event.target.value
															)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.referenceNumber}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>

							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.collectionDate' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.collectionDate}
										{internacionalData && IsNullOrEmpty(internacionalData.collectionDate) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														type='date'
														onBlur={(event) => {
															onUpdatePropertyCommon('CollectionDate', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.collectionDate}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														<Translate id='loadAlerts.countryOrigin' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.countryOrigin}
										{internacionalData && IsNullOrEmpty(internacionalData.countryOrigin) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('CountryOrigin', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.countryOrigin}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.downloadTerminal' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.downloadTerminal}
										{internacionalData && IsNullOrEmpty(internacionalData.downloadTerminal) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon(
																'DownloadTerminal',
																event.target.value
															)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.downloadTerminal}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.departureAirport' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.departureAirport}
										{internacionalData && IsNullOrEmpty(internacionalData.departureAirport) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon(
																'DepartureAirport',
																event.target.value
															)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.departureAirport}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.departureCity' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.departureCity}
										{internacionalData && IsNullOrEmpty(internacionalData.departureCity) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('DepartureCity', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.departureCity}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.arrivalAirport' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.arrivalAirport}
										{internacionalData && IsNullOrEmpty(internacionalData.arrivalAirport) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('ArrivalAirport', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.arrivalAirport}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.arrivalCity' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.arrivalCity}
										{internacionalData && IsNullOrEmpty(internacionalData.arrivalCity) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('ArrivalCity', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.arrivalCity}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem style={{ backgroundColor: 'rgb(229, 246, 253)' }}>
						<Grid container>
							<Grid item xs={12}>
								<Typography variant='h6'>
									{' '}
									<Translate id='loadAlerts.trackingGuideTitle' />
								</Typography>
								<Divider />
							</Grid>
							<Grid item xs={12}>
								<List>
									<ListItem>
										<ListItemIcon>
											<AddAlert />
										</ListItemIcon>
										<ListItemText primary={<Translate id='loadAlerts.selectCarrier' />} />
									</ListItem>
									<ListItem>
										<ListItemIcon>
											<AddAlert />
										</ListItemIcon>
										<ListItemText primary={<Translate id='loadAlerts.enterContainer' />} />
									</ListItem>
								</List>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '10px',
													// width: '50%',
												}}
											>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.carrier' />
													</strong>
												</Typography>
												{internacionalData && (
													<div style={{ whiteSpace: 'nowrap', width: '100%' }}>
														{canBeConectWithShipsGo ? (
															<ReactSelect
																placeholder={<Translate id='loadAlerts.carrierCode' />}
																options={carrierOptions}
																value={selectedCarrierOption}
																onChange={(selectedOption) => {
																	// setCarrier(selectedOption.value)
																	onUpdatePropertyCommon(
																		'CarrierCode',
																		selectedOption.value
																	)
																}}
																isDisabled={
																	!canBeEditData ||
																	internacionalData.trackingIsCreated
																}
																styles={{
																	control: (provided) => ({
																		...provided,
																		width: '100%',
																	}),
																	menu: (base) => ({
																		...base,
																		zIndex: 10,
																		maxHeight: 200,
																		width: '100%',
																	}),
																	menuList: (base) => ({
																		...base,
																		maxHeight: 200,
																		paddingTop: 0,
																		display: 'flex',
																		flexDirection: 'column',
																	}),
																	menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
																}}
															/>
														) : (
															<TextField
																fullWidth
																id='outlined-size-small'
																// label={<Translate id='loadAlerts.carrierCode' />}
																onBlur={(event) => {
																	onUpdatePropertyCommon(
																		'CarrierCode',
																		event.target.value
																	)
																}}
																placeholder='Ejem : DHL, FEDEX, UPS'
																variant='outlined'
																InputProps={{
																	style: {
																		padding: '5px',
																		height: '32px',
																	},
																}}
																disabled={!canBeEditData}
																defaultValue={internacionalData.carrierCode}
															/>
														)}
													</div>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
													gap: '10px',
													width: '100%', // Asegura que el contenedor ocupe el 100%
												}}
											>
												<Typography component='span' variant='body2' color='textPrimary'>
													<strong>
														<Translate id='loadAlerts.carrierWith' />
													</strong>
												</Typography>
												{internacionalData && (
													<div style={{ whiteSpace: 'nowrap', width: '100%' }}>
														<ReactSelect
															placeholder={<Translate id='loadAlerts.carrierWith' />}
															options={trackWithOptions}
															value={selectedTrackWithOption}
															onChange={(selectedOption) => {
																onUpdatePropertyCommon(
																	'TrackWith',
																	selectedOption.value
																)
															}}
															isDisabled={
																!canBeEditData || internacionalData.trackingIsCreated
															}
															styles={{
																control: (provided) => ({
																	...provided,
																	width: '100%', // Asegura que el control ocupe el 100%
																}),
																menu: (base) => ({
																	...base,
																	zIndex: 10,
																	maxHeight: 200,
																	width: '100%', // Asegura que el menú ocupe el 100%
																}),
																menuList: (base) => ({
																	...base,
																	maxHeight: 200,
																	paddingTop: 0,
																	display: 'flex',
																	flexDirection: 'column',
																	width: '100%', // Asegura que la lista del menú ocupe el 100%
																}),
																menuPortal: (base) => ({ ...base, zIndex: 9999 }),
															}}
														/>
													</div>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							{/* <Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.trackingGuide' />:
													</strong>
												</Typography>
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('TrackingGuide', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.trackingGuide}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid> */}
						</Grid>
					</ListItem>
					<Divider />
					<ListItem style={{ backgroundColor: 'rgb(229, 246, 253)' }}>
						<Grid container>
							{internacionalData.canBeAddedBookingNumber && (
								<Grid item xs={6}>
									<ListItemText
										secondary={
											<React.Fragment>
												<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
													<Typography
														component='span'
														variant='body2'
														color='textPrimary'
														style={{ whiteSpace: 'nowrap' }}
													>
														<strong>
															{' '}
															<Translate id='loadAlerts.bookingNumber' />:
														</strong>
													</Typography>
													{internacionalData && (
														<TextField
															fullWidth
															id='outlined-size-small'
															label={''}
															onBlur={(event) => {
																onUpdatePropertyCommon(
																	'BookingNumber',
																	event.target.value
																)
															}}
															variant='outlined'
															InputProps={{
																style: {
																	padding: '5px',
																	height: '32px',
																},
															}}
															disabled={
																!canBeEditData || internacionalData.trackingIsCreated
															}
															defaultValue={internacionalData.bookingNumber}
														/>
													)}
												</div>
											</React.Fragment>
										}
									/>
								</Grid>
							)}
							<Grid item xs={5}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.trackingGuide' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.trackingGuide}
										{internacionalData && IsNullOrEmpty(internacionalData.trackingGuide) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														onBlur={(event) => {
															onUpdatePropertyCommon('TrackingGuide', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData || internacionalData.trackingIsCreated}
														defaultValue={internacionalData.trackingGuide}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>

							<Grid item={1}>
								<Button
									color='primary'
									variant='contained'
									onClick={() =>
										onConfirmPasswordConfigModalForCreateTrackingGuide(internacionalData)
									}
									disabled={!internacionalData.canBeCreateTrackingNumber}
								>
									<Translate id='common.create' />
								</Button>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<React.Fragment>
											<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
												<Typography
													component='span'
													variant='body2'
													color='textPrimary'
													style={{ whiteSpace: 'nowrap' }}
												>
													<strong>
														{' '}
														<Translate id='loadAlerts.etd' />:
													</strong>
												</Typography>
												{/* {internacionalData && internacionalData.etd} */}
												{/* {internacionalData && IsNullOrEmpty(internacionalData.etd) && ( */}
												{internacionalData && (
													<TextField
														fullWidth
														id='outlined-size-small'
														label={''}
														type='date'
														onBlur={(event) => {
															onUpdatePropertyCommon('Etd', event.target.value)
														}}
														variant='outlined'
														InputProps={{
															style: {
																padding: '5px',
																height: '32px',
															},
														}}
														disabled={!canBeEditData}
														defaultValue={internacionalData.etd}
													/>
												)}
											</div>
										</React.Fragment>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Typography
												component='span'
												variant='body2'
												color='textPrimary'
												style={{ whiteSpace: 'nowrap' }}
											>
												<strong>
													{' '}
													<Translate id='loadAlerts.eta' />:
												</strong>
											</Typography>
											{/* {internacionalData && internacionalData.eta} */}
											{/* {internacionalData && IsNullOrEmpty(internacionalData.eta) && ( */}
											{internacionalData && (
												<TextField
													fullWidth
													id='outlined-size-small'
													label={''}
													type='date'
													onBlur={(event) => {
														onUpdatePropertyCommon('Eta', event.target.value)
													}}
													variant='outlined'
													InputProps={{
														style: {
															padding: '5px',
															height: '32px',
														},
													}}
													disabled={!canBeEditData}
													defaultValue={internacionalData.eta}
												/>
											)}
										</div>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListItem>
						<Grid container>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Typography
												component='span'
												variant='body2'
												color='textPrimary'
												style={{ whiteSpace: 'nowrap' }}
											>
												<strong>
													{' '}
													<Translate id='loadAlerts.incoterm' />:
												</strong>
											</Typography>
											{/* {internacionalData && internacionalData.incoterm} */}
											{/* {internacionalData && IsNullOrEmpty(internacionalData.incoterm) && ( */}
											{internacionalData && (
												<div style={{ whiteSpace: 'nowrap', width: '100%' }}>
													<ReactSelect
														placeholder={<Translate id='loadAlerts.incoterm' />}
														options={incotermOptions}
														value={selectedOption}
														styles={{
															control: (provided) => ({
																...provided,
																// height: '32px',
																// width: '500px',
																width: '100%',
															}),
															menu: (base) => ({
																...base,
																// zIndex: 10,
																// maxHeight: 200,
																// width: '250px',
																// minWidth: '100%',
																width: '100%',
															}),
															menuList: (base) => ({
																...base,
																// maxHeight: 200,
																// paddingTop: 0,
																// display: 'flex',
																// flexDirection: 'column',
																width: '100%',
															}),
															menuPortal: (base) => ({ ...base, zIndex: 9999 }), /// THIS IS TO SHOW MENU OVER MODAL
														}}
														menuPortalTarget={document.parentNode}
														menuPosition='fixed' //THIS IS REQUIRED IN MODAL!!
														menuPlacement={'auto'}
														onChange={(selectedOption) => {
															setOperationType(selectedOption.value)
															onUpdatePropertyCommon('Incoterm', selectedOption.value)
														}}
														isDisabled={!canBeEditData}
													/>
												</div>
											)}
										</div>
									}
								/>
							</Grid>
							<Grid item xs={6}>
								<ListItemText
									secondary={
										<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
											<Typography
												component='span'
												variant='body2'
												color='textPrimary'
												style={{ whiteSpace: 'nowrap' }}
											>
												<strong>
													{' '}
													<Translate id='loadAlerts.incotermPlace' />:
												</strong>
											</Typography>
											{/* {internacionalData && internacionalData.incotermPlace} */}
											{/* {internacionalData && IsNullOrEmpty(internacionalData.incotermPlace) && ( */}
											{internacionalData && (
												<TextField
													fullWidth
													id='outlined-size-small'
													label={''}
													onBlur={(event) => {
														onUpdatePropertyCommon('IncotermPlace', event.target.value)
													}}
													variant='outlined'
													InputProps={{
														style: {
															padding: '5px',
															height: '32px',
														},
													}}
													disabled={!canBeEditData}
													defaultValue={internacionalData.incotermPlace}
												/>
											)}
										</div>
									}
								/>
							</Grid>
						</Grid>
					</ListItem>
					<Divider />
					<ListDocumentsComponent
						panel='AddInvoiceDocuments'
						title='loadAlerts.invoice'
						documents={internacionalData.invoiceDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						deleteFiles={deleteInvoiceFiles}
						item={internacionalData}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddAnnexesDocumentes'
						title='loadAlerts.annexes'
						documents={internacionalData.annexesDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						deleteFiles={deleteAnnexesFiles}
						item={internacionalData}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
					<ListDocumentsComponent
						panel='AddInspectionDocument'
						title='loadAlerts.blDocument'
						documents={internacionalData.inspectionDocument}
						onLateralPanelConfig={onLateralPanelConfig}
						deleteFiles={deleteInspectionFiles}
						item={internacionalData}
						canBeEditData={canBeEditData}
						isSaving={isSaving}
					/>
				</List>
			)}
		</>
	)
}

InternationalComponent.propTypes = {}

InternationalComponent.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(InternationalComponent))
