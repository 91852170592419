//import react section
import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
/** Material UI import section */
import {
	Badge,
	Checkbox,
	FormControlLabel,
	FormGroup,
	IconButton,
	ListItemText,
	TextField,
	Typography,
	Grid,
	Button,
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Message } from '@material-ui/icons'
import { Translate } from 'react-localize-redux'
import { useSelector } from 'react-redux'
import ReactSelect from 'react-select'
import * as roles from '../../../../store/helpers/RolesHelper'
import { operationTyperOptions } from '../../../../store/helpers/SelectOptions'
import SelectSupplierComponent from './SelectSupplierComponent'

/** Resources import section */
const useStyle = (theme) => ({})

const GeneralData = (props) => {
	//function props section
	const { addPreviousOriging, onUpdateRequest, onOpenPanelHistoryMesages, onSyncInformationToSit } = props
	//Object props section
	const { loadAlert } = props
	//bool props section
	const { canBeEditData, isSaving } = props
	const [operationType, setOperationType] = useState('')
	let userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany').toJS())
console.log("loadAlert",loadAlert)
	useEffect(() => {
		if (loadAlert && loadAlert.operationType) {
			setOperationType(loadAlert.operationType)
		}
	}, [loadAlert])
	const selectedOption = operationTyperOptions.find((option) => option.value === operationType)
	const onUpdatePropertyCommon = (property, value) => {
		let objectProperties = {
			token: loadAlert.token,
		}
		objectProperties[property] = value
		onUpdateRequest(objectProperties)
	}
	const customStyles = {
		control: (provided) => ({
			...provided,
			width: '100%',
		}),
		menu: (provided) => ({
			...provided,
			width: '100%',
		}),
		menuList: (provided) => ({
			...provided,
			display: 'flex',
			flexDirection: 'column',
		}),
		singleValue: (provided) => ({
			...provided,
			width: '100%',
		}),
	}
	return (
		<>
			<Grid container spacing={8}>
				<Grid item xs={6} sm={4}>
					<Typography component='span' variant='body2' color='textPrimary' style={{ whiteSpace: 'nowrap' }}>
						<strong>
							{' '}
							<Translate id='loadAlerts.shippingOrder' />:
						</strong>
					</Typography>
					{/* {loadAlert.shippingOrder} */}
					{/* {loadAlert.status === LoadAlertStatus.DRAFT && ( */}
					<TextField
						fullWidth
						id='outlined-size-small'
						label={''}
						onBlur={(event) => {
							onUpdatePropertyCommon('ShippingOrder', event.target.value)
						}}
						variant='outlined'
						InputProps={{
							style: {
								padding: '5px',
								height: '32px',
							},
						}}
						defaultValue={loadAlert ? loadAlert.shippingOrder : '-'}
						disabled={!canBeEditData}
					/>
				</Grid>
				<Grid item xs={6} sm={4}>
					<Typography component='span' variant='body2' color='textPrimary' style={{ whiteSpace: 'nowrap' }}>
						<strong>
							{' '}
							<Translate id='loadAlerts.operationType' />:
						</strong>
					</Typography>
					<ReactSelect
						placeholder={<Translate id='loadAlerts.operationType' />}
						options={operationTyperOptions}
						value={selectedOption}
						onChange={(selectedOption) => {
							setOperationType(selectedOption.value)
							onUpdatePropertyCommon('OperationType', selectedOption.value)
						}}
						isDisabled={!canBeEditData}
						styles={customStyles}
					/>
				</Grid>
				<Grid item xs={6} sm={4}>
					<Typography component='span' variant='body2' color='textPrimary' style={{ whiteSpace: 'nowrap' }}>
						<strong>
							{' '}
							<Translate id='loadAlerts.internacionalReferenceNumber' />:
						</strong>
					</Typography>
					{/* {loadAlert.referenceNumber}
								{loadAlert.status === LoadAlertStatus.DRAFT && ( */}
					<TextField
						fullWidth
						id='outlined-size-small'
						label={''}
						onBlur={(event) => {
							onUpdatePropertyCommon('ReferenceNumber', event.target.value)
						}}
						variant='outlined'
						InputProps={{
							style: {
								padding: '5px',
								height: '32px',
							},
						}}
						defaultValue={loadAlert.referenceNumber}
						disabled={!canBeEditData}
					/>
				</Grid>
				<Grid item xs={12} sm={4}>
					<Typography component='span' variant='body2' color='textPrimary' style={{ whiteSpace: 'nowrap' }}>
						<strong>
							{' '}
							<Translate id='loadAlerts.sitFolio' />:
						</strong>
					</Typography>
					<TextField
						fullWidth
						id='outlined-size-small'
						label={''}
						onBlur={(event) => {
							onUpdatePropertyCommon('SITFolio', event.target.value)
						}}
						variant='outlined'
						InputProps={{
							style: {
								padding: '5px',
								height: '32px',
							},
						}}
						defaultValue={loadAlert.sitFolio}
						disabled={!roles.IsUserInRole(roles.PLANER_LOAD_ALERT, userRolesByCompany)}
					/>
				</Grid>
				<Grid item xs={12} sm={8}>
					<SelectSupplierComponent
						title={'Proveedor de material'}
						updateLoadAlertItemProperty={onUpdateRequest}
						item={loadAlert}
						generalData={true}
						canBeEditInternalUserData={canBeEditData}
						// canBeEditInternalUserData={roles.IsUserInRole(roles.PLANER_LOAD_ALERT, userRolesByCompany)}
					/>
				</Grid>
				<Grid item xs={12} sm={12}>
					<div style={{ display: 'flex', width: '100%' }}>
						<ListItemText
							secondary={
								<React.Fragment>
									<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
										<FormGroup row>
											{roles.IsUserInRole(roles.PLANER_LOAD_ALERT, userRolesByCompany) && (
												<FormControlLabel
													control={
														<Checkbox
															checked={
																loadAlert &&
																loadAlert.items &&
																loadAlert.items.some((x) => x.type === 3)
															}
															onChange={() => {
																if (canBeEditData) addPreviousOriging()
															}}
															value='Si'
														/>
													}
													label={<Translate id='loadAlerts.previousOriginSupplier' />}
												/>
											)}
										</FormGroup>
									</div>
								</React.Fragment>
							}
						/>

						<Button
							color='primary'
							variant='contained'
							onClick={onSyncInformationToSit}
							disabled={isSaving}
							size='small'
						>
							Guardar información
						</Button>
						{loadAlert && loadAlert.loadAlertHistories && (
							<Badge badgeContent={loadAlert.loadAlertHistories.length} color='primary'>
								<IconButton onClick={() => onOpenPanelHistoryMesages(loadAlert.loadAlertHistories)}>
									<Message />
								</IconButton>
							</Badge>
						)}
					</div>
				</Grid>
			</Grid>
		</>
	)
}

GeneralData.propTypes = {}

GeneralData.defaultProps = {}
export default withRouter(withStyles(useStyle, { withTheme: true })(GeneralData))
