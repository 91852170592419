import {
	Button,
	Card,
	CardHeader,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemText,
	Paper,
	Typography,
	Fab,
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import { AccountCircleOutlined, AddCircle, Assignment, Attachment, CloudDownload, Edit } from '@material-ui/icons'
import React, { useCallback, useEffect, useState } from 'react'
import { Translate } from 'react-localize-redux'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom/cjs/react-router-dom'
import yourlogohere from '../../resources/images/yourlogohere.png'
import { showPlotsFromPartNumber, doesUrlExist } from '../../store/helpers/ShowPlotToPartNumber'
import { searchByPartNumbersByQury } from '../../store/partNumbers/PartNumberAction'
import {
	editCustomsData,
	getDocumentsToCustomsBroker,
	loadShippingOrderFromBackEnd,
	removeFilesShippingOrder,
	sendAditionalDocumentsShippingOrderToClient,
	uploadFilesShippingOrder,
	foreignTradeSendInformation,
} from '../../store/shippingOrders/ShippingOrderAction'
import { loadSupplierCode } from '../../store/suppliers/SupplierActions'
import DataTable from '../common/DataTable'
import LateralPanelContainer from '../common/LateralPanelComponents/LateralPanelContainer'
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import IndexViewEdit from './components/IndexViewEdit'
import IndexSendDocuments from './components/indexSendDocuments'
import Toaster from '../common/Toaster'
import { loadAlertByFolioSITFromBack } from '../../store/loadAlerts/LoadAlertAction'
import DialogOperation from '../sustainability2/components/DialogOperation'
import {
	AvailableOperationsConfiguration,
	canBeEditSupplierAduanalInformation,
	ForeignTradeStatus,
} from '../../store/helpers/ImportRequestHelper'
import ConfirmPasswordDialog from '../common/ConfirmPasswordDialog'
import LoadingPage from '../common/LoadingPage'

const Show = () => {
	const dispatch = useDispatch()
	const params = useParams()
	const [identifierPanelLateral, setIdentifierPanelLateral] = useState('')
	const [documents, setDocuments] = useState([])
	const [dialogOperationModal, setDialogOperationModal] = useState(false)
	const [openDialogConfirm, setDialogConfirm] = useState({
		open: false,
		status: null,
		comments: '',
	})
	const [selectedItem, setSelectedItem] = useState({ destinationPlace: '' })
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})
	let userRolesByCompany = useSelector((state) => state.profile.get('userRolesByCompany').toJS())
	const shippingOrder = useSelector((state) =>
		state.shippingOrders.get('shippingOrder') ? state.shippingOrders.get('shippingOrder').toJS() : []
	)

	const userProfile = useSelector((state) => state.oidc.user)

	const isLoading = useSelector((state) => state.shippingOrders.get('isLoadingShipping'))

	const isSaving = useSelector((state) => state.shippingOrders.get('isSaving'))
	const supplier = useSelector((state) =>
		state.suppliers.get('supplier') ? state.suppliers.get('supplier').toJS() : null
	)
	const loadAlert = useSelector((state) =>
		state.loadAlert.get('loadAlert') ? state.loadAlert.get('loadAlert').toJS() : null
	)

	useEffect(() => {
		dispatch(getDocumentsToCustomsBroker(params.token)).then((data) => {
			setDocuments(data.Data[0])
		})
	}, [dispatch])

	let supplierLogo = supplier ? supplier.logo : ''
	let urlImageSupplier = `${process.env.REACT_APP_IDENTITY_SERVER}/${supplierLogo}`
	useEffect(() => {
		dispatch(loadShippingOrderFromBackEnd(params.token)).then((item) => {
			dispatch(loadAlertByFolioSITFromBack(item.folio))
			// dispatch(loadAlertByFolioSITFromBack('SI-0756-19'))
		})
	}, [dispatch])
	useEffect(() => {
		if (shippingOrder) dispatch(loadSupplierCode(shippingOrder.supplier_code))
	}, [dispatch])

	const onEditCustomsData = (data) => {
		dispatch(editCustomsData(data))
			.then(() => {
				onOpenAndCloseLateralPanel()
			})
			.catch(() => {
				console.error('Error saving delivery image evidence')
			})
	}

	const onSendAditionalDocuments = () => {
		dispatch(sendAditionalDocumentsShippingOrderToClient(shippingOrder.token, 'upload'))
			.then(() => {
				onOpenAndCloseLateralPanel()
			})
			.catch(() => {
				console.error('Error saving delivery image evidence')
			})
	}

	const onSendAditionalDocumentsWithStatus = () => {
		dispatch(foreignTradeSendInformation(shippingOrder.token, 'send'))
			.then(() => {
				setDialogConfirm({
					open: false,
					status: null,
					comments: '',
				})
			})
			.catch(() => {
				console.error('Error saving delivery image evidence')
			})
	}

	const onUploadAtitionalDocuments = (files) => {
		return dispatch(
			uploadFilesShippingOrder(shippingOrder.response.token, files, 'AditionalDocuments', 'aditional_documents')
		)
	}

	const onRemoveAditionalFiles = (fileName) => {
		return dispatch(
			removeFilesShippingOrder(
				shippingOrder.response.token,
				fileName,
				'AditionalDocuments',
				'aditional_documents'
			)
		)
	}

	const onUploadPaidRequestCustoms = (files) => {
		return dispatch(
			uploadFilesShippingOrder(shippingOrder.response.token, files, 'PaidRequestCustoms', 'paid_request_customs')
		)
	}

	const onRemovePaidRequestCustoms = (fileName) => {
		return dispatch(
			removeFilesShippingOrder(
				shippingOrder.response.token,
				fileName,
				'PaidRequestCustoms',
				'paid_request_customs'
			)
		)
	}

	const onUploadCustomsAgentExpenseAccount = (files) => {
		return dispatch(
			uploadFilesShippingOrder(
				shippingOrder.response.token,
				files,
				'CustomsAgentExpenseAccount',
				'customs_agent_expense_account'
			)
		)
	}

	const onRemoveCustomsAgentExpenseAccount = (fileName) => {
		return dispatch(
			removeFilesShippingOrder(
				shippingOrder.response.token,
				fileName,
				'CustomsAgentExpenseAccount',
				'customs_agent_expense_account'
			)
		)
	}

	const onUploadCustomsAgentAdvancePaymentRequest = (files) => {
		return dispatch(
			uploadFilesShippingOrder(
				shippingOrder.response.token,
				files,
				'CustomsAgentAdvancePaymentRequest',
				'customs_agent_advance_payment_request'
			)
		)
	}

	const onRemoveCustomsAgentAdvancePaymentRequest = (fileName) => {
		return dispatch(
			removeFilesShippingOrder(
				shippingOrder.response.token,
				fileName,
				'CustomsAgentAdvancePaymentRequest',
				'customs_agent_advance_payment_request'
			)
		)
	}

	const PanelLateralChildren = useCallback(
		{
			IndexViewEdit: (
				<IndexViewEdit
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					item={shippingOrder}
					onEditRequest={onEditCustomsData}
					disabled={isSaving}
				/>
			),
			IndexSendDocuments: (
				<IndexSendDocuments
					onCloseModal={() => onOpenAndCloseLateralPanel()}
					item={shippingOrder}
					onEditRequest={onSendAditionalDocuments}
					onUploadAtitionalDocuments={onUploadAtitionalDocuments}
					onRemoveFile={onRemoveAditionalFiles}
					onUploadPaidRequestCustoms={onUploadPaidRequestCustoms}
					onRemovePaidRequestCustoms={onRemovePaidRequestCustoms}
					onUploadCustomsAgentExpenseAccount={onUploadCustomsAgentExpenseAccount}
					onRemoveCustomsAgentExpenseAccount={onRemoveCustomsAgentExpenseAccount}
					onUploadCustomsAgentAdvancePaymentRequest={onUploadCustomsAgentAdvancePaymentRequest}
					onRemoveCustomsAgentAdvancePaymentRequest={onRemoveCustomsAgentAdvancePaymentRequest}
					disabled={isSaving}
				/>
			),
		},
		[identifierPanelLateral, selectedItem]
	)

	const onRowClick = (item, event) => {
		onLateralPanelConfig('IndexViewEdit')
		setSelectedItem(item)
	}

	const onRowClickAditionalDocuments = (item, event) => {
		onLateralPanelConfig('IndexSendDocuments')
		setSelectedItem(item)
	}

	const onLateralPanelConfig = (identifier) => {
		setIdentifierPanelLateral(identifier)
		onOpenAndCloseLateralPanel()
	}

	const { LateralPanelComponent, onOpenAndCloseLateralPanel } = LateralPanelContainer({
		children: PanelLateralChildren[identifierPanelLateral],
		identifier: identifierPanelLateral,
	})

	const onViewPartNumber = async (query) => {
		try {
			// Dispatch the search query and wait for the result
			const data = await dispatch(searchByPartNumbersByQury(query))

			if (data) {
				let namePlot = data[0].drawingName
				const companyToken = data[0].companyToken
				const formatype = '0' // Ajusta el formato si es necesario

				// Intentar hasta 5 peticiones
				for (let revision = 1; revision <= 5; revision++) {
					namePlot = `${namePlot}R0${revision}.pdf`
					const urlPlot = `${process.env.REACT_APP_SIP_SUPPLIERS}/partnumbers/${namePlot}/${companyToken}/${revision}/${formatype}`
					console.log('urlPlot', urlPlot)

					// Check if the URL exists
					const urlExists = await doesUrlExist(urlPlot)

					if (urlExists) {
						// Show the plot if the URL exists
						showPlotsFromPartNumber(namePlot, companyToken, revision, formatype)
						return // Salir de la función si se encuentra una URL válida
					}
				}

				// Si ninguna URL es válida
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'plotSelector.requestedPlanNotAvailable',
					toasterVariant: 'info',
				})
			}
		} catch (error) {
			console.error('Error fetching part number:', error)
			setToasterMessageConfig({
				showToaster: true,
				toasterMessage: 'plotSelector.problemObtainingPlan',
				toasterVariant: 'info',
			})
		}
	}

	const internacionalData = loadAlert && loadAlert.items ? loadAlert.items.find((x) => x.type === 1) : null
	const previousOriginData = loadAlert && loadAlert.items ? loadAlert.items.find((x) => x.type === 3) : null
	const closeDialogOperation = () => {
		setDialogOperationModal(!dialogOperationModal)
	}

	const onOpenConfirmDialog = (status) => {
		setDialogConfirm({ open: true, status: status })
	}

	let canBeEditSupplierAduanalInf = canBeEditSupplierAduanalInformation(
		shippingOrder,
		userRolesByCompany,
		userProfile
	)

	let actualStatus = shippingOrder ? shippingOrder.foreign_trade_status : 0
	let canUploadData =
		canBeEditSupplierAduanalInf &&
		(actualStatus === ForeignTradeStatus.Draft || actualStatus === ForeignTradeStatus.Undefined)

	const onGetMessage1ModalConfirmFromItem = () => {
		switch (openDialogConfirm.status) {
			case ForeignTradeStatus.Review:
				return <Translate id='loadAlerts.dialogsOptions.messageToSendToReviewFreigTrade' />
			default:
				return 'Not implemented operation'
		}
	}

	if (isLoading) return <LoadingPage />

	const onRendercustomCell = (dataSource, item) => {
		switch (dataSource) {
			case 'options': {
				return (
					// <div style={{ width: '200px' }}>
						<Fab
							// variant='extended'
							size='small'
							color='primary'
							// style={{width:"200px"}}
							// onClick={() => setOpenDialog(true)}
							onClick={() => onViewPartNumber(item.part_number_code)}
							data-cy='btn-available-operations'
						>
							<PictureAsPdf />
							{/* Ver plano */}
						</Fab>
					// </div>
				)
			}
			default:
				return ''
		}
	}

	return (
		<Paper style={{ padding: '10px' }}>
			{/* <FloatingButton open={setOpenDialog} /> */}
			{
				<Fab
					variant='extended'
					size='small'
					color='primary'
					// onClick={() => setOpenDialog(true)}
					onClick={closeDialogOperation}
					data-cy='btn-available-operations'
				>
					<MenuIcon />
					<Translate id='common.AvailableOperations' />
				</Fab>
			}
			<Card style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
				<CardHeader
					title={'Folio :' + shippingOrder.folio}
					avatar={
						<img
							src={supplierLogo ? urlImageSupplier : yourlogohere}
							alt='logoSupplier'
							style={{
								maxHeight: 80,
							}}
						/>
					}
					subheader={shippingOrder.creation_date_long}
				/>
				<div style={{ display: 'flex', flexDirection: 'column', margin: '20px' }}>
					<Typography style={{ color: 'gray' }}>{shippingOrder.full_supplier}</Typography>
					{supplier && <Typography style={{ color: 'gray' }}>{supplier.rfc}</Typography>}
					{supplier && <Typography style={{ color: 'gray' }}>{supplier.full_address}</Typography>}
				</div>
			</Card>
			{/* <DialogAvailableOperations
				userRolesByCompany={userRolesByCompany}
				options={optonsAvalinable}
				openAvailableOperationsDialog={openAvailableOperationsDialog}
				setOpenDialog={setOpenDialog}
				onSendCustomsBrokerInformation={onSendAditionalDocumentsWithStatus}
			/> */}

			{dialogOperationModal && (
				<DialogOperation
					open={dialogOperationModal}
					onClose={closeDialogOperation}
					options={AvailableOperationsConfiguration(
						shippingOrder ? shippingOrder.foreign_trade_status : 0,
						onOpenConfirmDialog,
						canBeEditSupplierAduanalInf
					)}
				/>
			)}
			<List style={{ backgroundColor: '#F9F9F9' }}>
				<ListItem>
					<ListItemText
						primary={
							<div style={{ display: 'flex', gap: '15px' }}>
								<AccountCircleOutlined style={{ color: '#86EDCD' }} />
								<Typography component='span' variant='body2' color='textSecondary'>
									<strong>
										{' '}
										<Translate id='importRequests.customsBroker' />
									</strong>
								</Typography>
							</div>
						}
					/>
					{canUploadData && (
						<div>
							<Button onClick={() => onRowClick(shippingOrder)}>
								<Typography style={{ marginRight: '8px' }}>
									<Translate id='common.edit' />
								</Typography>
								<Edit style={{ color: '#4377C4' }} />
							</Button>
						</div>
					)}
				</ListItem>
			</List>
			<List>
				<ListItem>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography component='span' variant='body2' color='textPrimary'>
									<strong>
										<Translate id='importRequests.customsBrokerReference' />:
									</strong>
								</Typography>
								{shippingOrder.advance_agent_customs_reference}
							</React.Fragment>
						}
					/>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography component='span' variant='body2' color='textPrimary'>
									<strong>
										<Translate id='importRequests.customsBrokerAdvance' />:
									</strong>
								</Typography>
								{shippingOrder.advance_agent_customs_payment}
							</React.Fragment>
						}
					/>
				</ListItem>
				<ListItem>
					<ListItemText
						secondary={
							<React.Fragment>
								<Typography component='span' variant='body2' color='textPrimary'>
									<strong>
										{' '}
										<Translate id='common.comments' />:
									</strong>
								</Typography>
								{shippingOrder.observations}
							</React.Fragment>
						}
					/>
				</ListItem>
			</List>
			<Divider />
			<List style={{ backgroundColor: '#F9F9F9' }}>
				<ListItem>
					<Assignment style={{ color: '#86EDCD' }} />
					<ListItemText
						secondary={
							<Typography component='span' variant='body2' color='textSecondary'>
								<strong>
									{' '}
									<Translate id='importRequests.partNumbers' />
								</strong>
							</Typography>
						}
					/>
				</ListItem>
				<DataTable
					data={shippingOrder.details}
					configuration={TableConfiguration}
					onRowClick={(item) => onViewPartNumber(item.part_number_code)}
					onRenderCellItem={onRendercustomCell}
				/>
			</List>
			<List>
				<ListItem>
					<Assignment style={{ color: '#86EDCD' }} />
					<ListItemText
						secondary={
							<Typography component='span' variant='body2' color='textSecondary'>
								<strong>
									{' '}
									<Translate id='importRequests.sharedFiles' />
								</strong>
							</Typography>
						}
					/>
				</ListItem>
			</List>

			{
				<List
					style={{
						backgroundColor: '#F9F9F9',
						borderLeft: '5px solid #F9F9F9',
					}}
					subheader={<span style={{ fontWeight: 'bold' }}>Archivos proveedor extranjero</span>}
				>
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='loadAlerts.invoice' />
								</strong>
							}
						/>
					</ListItem>
					{loadAlert &&
						internacionalData &&
						internacionalData.invoiceDocument &&
						internacionalData.invoiceDocument.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='loadAlerts.blDocument' />
								</strong>
							}
						/>
					</ListItem>
					{loadAlert &&
						internacionalData &&
						internacionalData.inspectionDocument &&
						internacionalData.inspectionDocument.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}

					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='loadAlerts.annexes' />
								</strong>
							}
						/>
					</ListItem>
					{loadAlert &&
						internacionalData &&
						internacionalData.annexesDocument &&
						internacionalData.annexesDocument.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
				</List>
			}
			<br />
			<List
				style={{
					backgroundColor: '#F9F9F9',
					borderLeft: '5px solid #F9F9F9',
				}}
				subheader={<span style={{ fontWeight: 'bold' }}>Archivos previo origen</span>}
			>
				<ListItem>
					<Attachment style={{ color: '#BDBDBD' }} />
					<ListItemText
						secondary={
							<strong>
								<Translate id='loadAlerts.previousDocument' />
							</strong>
						}
					/>
				</ListItem>
				{loadAlert &&
					previousOriginData &&
					previousOriginData.previousDocument &&
					previousOriginData.previousDocument.map((item) => {
						return (
							<ListItem style={{ backgroundColor: 'white' }}>
								<ListItemText primary={<Typography>{item.name}</Typography>} />
								<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
									<IconButton
										disabled={isSaving}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
									>
										<CloudDownload style={{ color: '#4377C4' }} />
									</IconButton>
								</div>
							</ListItem>
						)
					})}

				<ListItem>
					<Attachment style={{ color: '#BDBDBD' }} />
					<ListItemText
						secondary={
							<strong>
								<Translate id='loadAlerts.invoice' />
							</strong>
						}
					/>
				</ListItem>
				{loadAlert &&
					previousOriginData &&
					previousOriginData.invoiceDocument &&
					previousOriginData.invoiceDocument.map((item) => {
						return (
							<ListItem style={{ backgroundColor: 'white' }}>
								<ListItemText primary={<Typography>{item.name}</Typography>} />
								<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
									<IconButton
										disabled={isSaving}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
									>
										<CloudDownload style={{ color: '#4377C4' }} />
									</IconButton>
								</div>
							</ListItem>
						)
					})}

				<ListItem>
					<Attachment style={{ color: '#BDBDBD' }} />
					<ListItemText
						secondary={
							<strong>
								<Translate id='loadAlerts.annexes' />
							</strong>
						}
					/>
				</ListItem>
				{loadAlert &&
					previousOriginData &&
					previousOriginData.annexesDocument &&
					previousOriginData.annexesDocument.map((item) => {
						return (
							<ListItem style={{ backgroundColor: 'white' }}>
								<ListItemText primary={<Typography>{item.name}</Typography>} />
								<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
									<IconButton
										disabled={isSaving}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
									>
										<CloudDownload style={{ color: '#4377C4' }} />
									</IconButton>
								</div>
							</ListItem>
						)
					})}

				<ListItem>
					<Attachment style={{ color: '#BDBDBD' }} />
					<ListItemText
						secondary={
							<strong>
								<Translate id='loadAlerts.inspectionDocument' />
							</strong>
						}
					/>
				</ListItem>
				{loadAlert &&
					previousOriginData &&
					previousOriginData.inspectionDocument &&
					previousOriginData.inspectionDocument.map((item) => {
						return (
							<ListItem style={{ backgroundColor: 'white' }}>
								<ListItemText primary={<Typography>{item.name}</Typography>} />
								<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
									<IconButton
										disabled={isSaving}
										target='_blank'
										href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
									>
										<CloudDownload style={{ color: '#4377C4' }} />
									</IconButton>
								</div>
							</ListItem>
						)
					})}
			</List>

			<br />

			{
				<List
					style={{
						backgroundColor: '#F9F9F9',
						borderLeft: '5px solid #F9F9F9',
					}}
					subheader={<span style={{ fontWeight: 'bold' }}>Documentos de proveedor de material</span>}
				>
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.invoice' />
								</strong>
							}
						/>
					</ListItem>
					{shippingOrder.invoices &&
						shippingOrder.invoices.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					{documents.InvoiceAttachments &&
						documents.InvoiceAttachments.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.Name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton disabled={isSaving} target='_blank' href={item.FilePath}>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.packingSerialNumber' />
								</strong>
							}
						/>
					</ListItem>
					{shippingOrder.packing &&
						shippingOrder.packing.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					{documents.PackingAttachments &&
						documents.PackingAttachments.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.Name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton disabled={isSaving} target='_blank' href={item.FilePath}>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.certificate' />
								</strong>
							}
						/>
					</ListItem>
					{shippingOrder.certificate_origin &&
						shippingOrder.certificate_origin.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					{documents.CertificateAttachments &&
						documents.CertificateAttachments.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.Name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton disabled={isSaving} target='_blank' href={item.FilePath}>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.qualityCertificate' />
								</strong>
							}
						/>
					</ListItem>
					{shippingOrder.certificate_quality &&
						shippingOrder.certificate_quality.map((item) => {
							return (
								<ListItem style={{ backgroundColor: 'white' }}>
									<ListItemText primary={<Typography>{item.name}</Typography>} />
									<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
										<IconButton
											disabled={isSaving}
											target='_blank'
											href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
										>
											<CloudDownload style={{ color: '#4377C4' }} />
										</IconButton>
									</div>
								</ListItem>
							)
						})}
					<Divider />
					<List style={{ backgroundColor: 'white' }}>
						<ListItem>
							<Assignment style={{ color: '#86EDCD' }} />
							<ListItemText
								secondary={
									<Typography component='span' variant='body2' color='textSecondary'>
										<strong> Archivos agente aduanal</strong>
									</Typography>
								}
							/>
						</ListItem>
					</List>
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.additionalDocuments' />
								</strong>
							}
						/>
						{canUploadData && (
							<Button disabled={isSaving} onClick={() => onRowClickAditionalDocuments(shippingOrder)}>
								<Typography style={{ marginRight: '8px' }}>
									<Translate id='importRequests.addDocument' />
								</Typography>
								<AddCircle style={{ color: '#4377C4' }} />
							</Button>
						)}
					</ListItem>

					{shippingOrder.aditional_documents &&
						shippingOrder.aditional_documents.map((item) => {
							return (
								<>
									<ListItem style={{ backgroundColor: 'white' }}>
										<ListItemText primary={<Typography>{item.name}</Typography>} />
										<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
											<IconButton
												disabled={isSaving}
												target='_blank'
												href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
											>
												<CloudDownload style={{ color: '#4377C4' }} />
											</IconButton>
										</div>
									</ListItem>
									<Divider />
								</>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.addPaidRequestCustoms' />
								</strong>
							}
						/>
						{canUploadData && (
							<Button onClick={() => onRowClickAditionalDocuments(shippingOrder)}>
								<Typography style={{ marginRight: '8px' }}>
									<Translate id='importRequests.addDocument' />
								</Typography>
								<AddCircle style={{ color: '#4377C4' }} />
							</Button>
						)}
					</ListItem>
					{shippingOrder.paid_request_customs &&
						shippingOrder.paid_request_customs.map((item) => {
							return (
								<>
									<ListItem style={{ backgroundColor: 'white' }}>
										<ListItemText primary={<Typography>{item.name}</Typography>} />
										<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
											<IconButton
												disabled={isSaving}
												target='_blank'
												href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
											>
												<CloudDownload style={{ color: '#4377C4' }} />
											</IconButton>
										</div>
									</ListItem>
									<Divider />
								</>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.addCustomsAgentExpenseAccount' />
								</strong>
							}
						/>
						{canUploadData && (
							<Button disabled={isSaving} onClick={() => onRowClickAditionalDocuments(shippingOrder)}>
								<Typography style={{ marginRight: '8px' }}>
									<Translate id='importRequests.addDocument' />
								</Typography>
								<AddCircle style={{ color: '#4377C4' }} />
							</Button>
						)}
					</ListItem>
					{shippingOrder.customs_agent_expense_account &&
						shippingOrder.customs_agent_expense_account.map((item) => {
							return (
								<>
									<ListItem style={{ backgroundColor: 'white' }}>
										<ListItemText primary={<Typography>{item.name}</Typography>} />
										<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
											<IconButton
												disabled={isSaving}
												target='_blank'
												href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
											>
												<CloudDownload style={{ color: '#4377C4' }} />
											</IconButton>
										</div>
									</ListItem>
									<Divider />
								</>
							)
						})}
					<ListItem>
						<Attachment style={{ color: '#BDBDBD' }} />
						<ListItemText
							secondary={
								<strong>
									<Translate id='importRequests.addCustomsAgentAdvancePaymentRequest' />
								</strong>
							}
						/>
						{canUploadData && (
							<Button onClick={() => onRowClickAditionalDocuments(shippingOrder)}>
								<Typography style={{ marginRight: '8px' }}>
									<Translate id='importRequests.addDocument' />
								</Typography>
								<AddCircle style={{ color: '#4377C4' }} />
							</Button>
						)}
					</ListItem>
					{shippingOrder.customs_agent_advance_payment_request &&
						shippingOrder.customs_agent_advance_payment_request.map((item) => {
							return (
								<>
									<ListItem style={{ backgroundColor: 'white' }}>
										<ListItemText primary={<Typography>{item.name}</Typography>} />
										<div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
											<IconButton
												disabled={isSaving}
												target='_blank'
												href={`${process.env.REACT_APP_SIP_URL_FILES_PLANNING}/${item.relativePath}`}
											>
												<CloudDownload style={{ color: '#4377C4' }} />
											</IconButton>
										</div>
									</ListItem>
									<Divider />
								</>
							)
						})}
				</List>
			}
			<LateralPanelComponent />
			<Toaster
				message={
					<Translate id={toasterMessageConfig.toasterMessage} data={{ name: toasterMessageConfig.name }} />
				}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>

			{openDialogConfirm && openDialogConfirm.open && (
				<ConfirmPasswordDialog
					open={openDialogConfirm.open}
					title={<Translate id='common.confirmOperation' />}
					message1={onGetMessage1ModalConfirmFromItem()}
					message2={<Translate id='common.enterPassword' />}
					onClickButtonConfirm={(message) => {
						return onSendAditionalDocumentsWithStatus()
					}}
					onClose={() =>
						setDialogConfirm({
							open: false,
							status: null,
							comments: '',
						})
					}
					isSaving={isSaving}
					isVisibleComments={false}
					commentRequired={false}
				/>
			)}
		</Paper>
	)
}
const TableConfiguration = {
	columns: [
		{
			header: <Translate id='shippingOrder.purchaseOrder' />,
			dataSource: 'purchase_order',
		},
		{
			header: <Translate id='shippingOrder.codePartNumber' />,
			dataSource: 'part_number_code',
		},
		{
			header: <Translate id='shippingOrder.productoDescription' />,
			dataSource: 'part_number_description',
		},
		{
			header: <Translate id='shippingOrder.quantityofMaterial' />,
			dataSource: 'quantity_of_material',
		},
		{
			header: <Translate id='shippingOrder.productoMeasurementUnit' />,
			dataSource: 'part_number_um',
		},
		{
			header: <Translate id='shippingOrder.unitPrice' />,
			dataSource: 'price',
		},
		{
			header: 'Ver pdf plano',
			dataSource: 'options',
		},
	],
}
export default withauthenticatedLayout(Show)
