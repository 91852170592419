import React, { useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import { Translate, getTranslate } from 'react-localize-redux'

/** Material UI import section */
import { withStyles } from '@material-ui/core/styles'

/** Custom components import section */
import withauthenticatedLayout from '../layouts/withauthenticatedLayout'
import Toaster from '../common/Toaster'
import DesktopView from './components/DesktopView'
import SeeFileAcknowledgmentFilesDialog from './components/SeeFileAcknowledgmentFilesDialog'
/** Redux import section */
import { useSelector, useDispatch } from 'react-redux'
import { consignmentShowSelector } from '../../store/consignmentNote/consignmentNoteSelectors'
import { converListToArray } from '../../store/helpers/ListHelper'
import { getErrorInChangeStatus } from '../../store/helpers/ConsigmentNoteHelper'

/** Actions import section */
import {
	loadConsignmentNoteDetail,
	uploadFilesConsignmentNote,
	downloadConsignmentNoteExcel,
	uploadAdditionaCostConsignmentNote,
	downloadConsignmentNoteAttachments,
	deleteConsignmentNoteAttachments,
	deleteAdditionalExpenseAttachments,
	downloadAdditionaCostConsignmentNote,
	uploadAcknowledgmentFiles,
	deleteAcknowledgmentFiles,
	downloadAcknowledgmentFiles,
	getAcknowledgmentFileByName,
	changeStatusFromBackEnd,
	createDevolutionFromBackEnd,
	deleteMaterial,
	deleteDestination,
	loadDeliveriesRequestFromBackEnd,
	generateReport,
	updateDeliveryFromBackEnd,
	updatePropertyNotesFromBackEnd,
	uploadEvidenceDelivery,
	downloadEvidenceDeliveryFiles,
	deleteEvidencDeliveryFiles,
	deleteEvidenceDeliveryFromBackend
} from '../../store/consignmentNote/consignmentNoteActions'

const Show = (props) => {
	const dispatch = useDispatch()
	const consignmentNoteId = props.match.params.token

	/** Redux store information section*/
	const isLoading = useSelector((state) => state.consignmentNote.get('isLoading'))
	const identifier = useSelector((state) => state.consignmentNote.get('identifier'))
	const isSaving = useSelector((state) => state.consignmentNote.get('isSaving'))
	const isDonwloading = useSelector((state) => state.consignmentNote.get('isDonwloading'))
	const translate = useSelector((state) => getTranslate(state.localize))
	const userProfile = useSelector((state) => state.oidc.user)
	const consignmentNote = useSelector((state) =>
		state.consignmentNote.get('consignmentNote') ? state.consignmentNote.get('consignmentNote').toJS() : null
	)
	const deliveries = useSelector(consignmentShowSelector)
	const userRolesByCompany = useSelector((state) =>
		state.profile.get('userRolesByCompany') ? converListToArray(state.profile.get('userRolesByCompany')) : []
	)

	/** Defines local state component */
	const [downloadingAttachments, setDownloadingAttachments] = useState(false)
	const [isOpenSeeFileDialog, setIsOpenSeeFileDialog] = useState({ open: false, file: null })
	const [downloadingAdditionalExpenseFiles, setDownloadingAdditionalExpenseFiles] = useState(false)
	const [downloadingAcknowledgmentReceipts, setDownloadingAcknowledgmentReceipts] = useState(false)
	const [toasterMessageConfig, setToasterMessageConfig] = useState({
		showToaster: false,
		toasterMessage: null,
		toasterVariant: '',
		name: '',
	})

	/** Load consignment note data */
	useEffect(() => {
		dispatch(loadConsignmentNoteDetail(consignmentNoteId))
	}, [dispatch, consignmentNoteId])

	useEffect(() => {
		dispatch(loadDeliveriesRequestFromBackEnd())
	}, [dispatch])

	const onUploadConsignmentNoteFiles = (files) => {
		return dispatch(uploadFilesConsignmentNote(consignmentNoteId, files))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				return Promise.resolve(true)
			})
			.catch((errors) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				return Promise.reject(errors)
			})
	}

	const removeConsignmentNoteFiles = () => {
		return dispatch(deleteConsignmentNoteAttachments(consignmentNoteId))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				return Promise.resolve(true)
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const downloadAttachments = () => {
		setDownloadingAttachments(true)
		dispatch(downloadConsignmentNoteAttachments(consignmentNoteId))
			.then(() => {})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAttachments(false))
	}

	const onDownloadReport = () => {
		dispatch(downloadConsignmentNoteExcel(consignmentNoteId))
			.then(() => {})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}

	const onGenerateReport = () => {
		dispatch(generateReport(consignmentNoteId))
			.then(() => {})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToDownload',
					toasterVariant: 'error',
				})
			})
	}

	const uploadAdditionalExpenseFiles = (files) => {
		return dispatch(uploadAdditionaCostConsignmentNote(consignmentNoteId, files))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				return Promise.resolve(true)
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const downloadAdditionalExpenseAttachments = () => {
		setDownloadingAdditionalExpenseFiles(true)
		dispatch(downloadAdditionaCostConsignmentNote(consignmentNoteId))
			.then(() => {})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAdditionalExpenseFiles(false))
	}

	const removeAdditionalExpenseFiles = () => {
		return dispatch(deleteAdditionalExpenseAttachments(consignmentNoteId))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const uploadAcknowledgmentReceipts = (recipientCode, files, deliveryCode) => {
		return dispatch(uploadAcknowledgmentFiles(consignmentNoteId, recipientCode, files, deliveryCode))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				return Promise.resolve(true)
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const removeAcknowledgmentReceipts = (deliveryId, recipientCode, deliveryCode) => {
		return dispatch(deleteAcknowledgmentFiles(consignmentNoteId, deliveryId, recipientCode, deliveryCode))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const downloadAcknowledgmentReceipts = (deliveryId, recipientCode, deliveryCode) => {
		setDownloadingAcknowledgmentReceipts(true)
		dispatch(downloadAcknowledgmentFiles(consignmentNoteId, deliveryId, recipientCode, deliveryCode))
			.then(() => {})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAcknowledgmentReceipts(false))
	}


	const onUploadEvidenceMerchandise = (recipientCode, files, deliveryCode) => {
		return dispatch(uploadEvidenceDelivery(consignmentNoteId, recipientCode, files, deliveryCode))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				return Promise.resolve(true)
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const removeEvidenceMerchandise = (deliveryId, recipientCode, deliveryCode) => {
		return dispatch(deleteEvidencDeliveryFiles(consignmentNoteId, deliveryId, recipientCode, deliveryCode))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject(false)
			})
	}

	const downloadEvidenceMerchandise = (deliveryId, recipientCode, deliveryCode) => {
		setDownloadingAcknowledgmentReceipts(true)
		dispatch(downloadEvidenceDeliveryFiles(consignmentNoteId, deliveryId, recipientCode, deliveryCode))
			.then(() => {})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAcknowledgmentReceipts(false))
	}

	const onGetgetAcknowledgmentFileByName = (deliveryId, recipientCode, deliveryCode, fileName, attachmentType) => {
		setDownloadingAcknowledgmentReceipts(true)
		dispatch(
			getAcknowledgmentFileByName(
				consignmentNoteId,
				deliveryId,
				recipientCode,
				deliveryCode,
				fileName,
				attachmentType
			)
		)
			.then((data) => {
				setIsOpenSeeFileDialog({ open: !isOpenSeeFileDialog.open, file: data })
			})
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAcknowledgmentReceipts(false))
	}

	const onDeleteEvidenceMerchansediseFromBackend = (deliveryId, recipientCode, deliveryCode, fileName, attachmentType) => {
		setDownloadingAcknowledgmentReceipts(true)
		dispatch(
			deleteEvidenceDeliveryFromBackend(
				consignmentNoteId,
				deliveryId,
				recipientCode,
				deliveryCode,
				fileName,
				attachmentType
			)
		)
			// .then((data) => {
				
			// })
			.catch((_) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
			.finally(() => setDownloadingAcknowledgmentReceipts(false))
	}

	

	const onChangeStatusFromBackEnd = (status) => {
		return dispatch(changeStatusFromBackEnd(status, consignmentNoteId))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: getErrorInChangeStatus(response),
					toasterVariant: 'error',
				})
			})
	}

	const onCreateDevolutionFromBackEnd = (delivery, item, confirmQuality) => {
		return dispatch(createDevolutionFromBackEnd(consignmentNoteId, delivery, item, confirmQuality))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				Promise.resolve()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onDeleteMaterialFromBackEnd = (delivery, item) => {
		return dispatch(deleteMaterial(consignmentNoteId, delivery.id, item.id))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				Promise.resolve()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject()
			})
	}

	const onDeleteDestinationFromBackEnd = (delivery) => {
		return dispatch(deleteDestination(consignmentNoteId, delivery.id))
			.then(() => {
				dispatch(loadConsignmentNoteDetail(consignmentNoteId))
				Promise.resolve()
			})
			.catch((response) => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
				Promise.reject()
			})
	}

	const onUpdateDeliveryFromBackEnd = (delivery) => {
		return dispatch(updateDeliveryFromBackEnd(consignmentNoteId, delivery))
			.then(() => {
				Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	const onUpdatePropertyNotesFromBackEnd = (data) => {
		return dispatch(updatePropertyNotesFromBackEnd(consignmentNoteId, data))
			.then(() => {
				Promise.resolve()
			})
			.catch(() => {
				setToasterMessageConfig({
					showToaster: true,
					toasterMessage: 'common.errorToSave',
					toasterVariant: 'error',
				})
			})
	}

	return (
		<>
			<DesktopView
				isLoading={isLoading}
				identifier={identifier}
				consignmentNote={consignmentNote}
				deliveries={deliveries}
				translate={translate}
				userProfile={userProfile}
				isSaving={isSaving}
				onUploadFiles={onUploadConsignmentNoteFiles}
				onRemoveFiles={removeConsignmentNoteFiles}
				onDownloadFiles={downloadAttachments}
				downloadingFiles={downloadingAttachments}
				onUploadAdditionalExpenseFiles={uploadAdditionalExpenseFiles}
				onDownloadAdditionalExpenseFiles={downloadAdditionalExpenseAttachments}
				downloadingAdditionalExpenseFiles={downloadingAdditionalExpenseFiles}
				onRemoveAdditionalExpenseFiles={removeAdditionalExpenseFiles}
				onUploadAcknowledgmentReceipts={uploadAcknowledgmentReceipts}
				onRemoveAcknowledgmentReceipts={removeAcknowledgmentReceipts}
				onDownloadAcknowledgmentReceipts={downloadAcknowledgmentReceipts}
				onGetgetAcknowledgmentFileByName={onGetgetAcknowledgmentFileByName}
				onUploadEvidenceMerchandise={onUploadEvidenceMerchandise}
				downloadingAcknowledgmentReceipts={downloadingAcknowledgmentReceipts}
				onDownloadReport={onDownloadReport}
				onGenerateReport={onGenerateReport}
				isDonwloading={isDonwloading}
				userRolesByCompany={userRolesByCompany}
				onChangeStatusFromBackEnd={onChangeStatusFromBackEnd}
				onCreateDevolutionFromBackEnd={onCreateDevolutionFromBackEnd}
				onDeleteMaterialFromBackEnd={onDeleteMaterialFromBackEnd}
				onDeleteDestinationFromBackEnd={onDeleteDestinationFromBackEnd}
				onUpdateDelivery={onUpdateDeliveryFromBackEnd}
				onUpdatePropertyNotes={onUpdatePropertyNotesFromBackEnd}
				removeEvidenceMerchandise={removeEvidenceMerchandise}
				downloadEvidenceMerchandise={downloadEvidenceMerchandise}
				onDeleteEvidenceMerchansediseFromBackend={onDeleteEvidenceMerchansediseFromBackend}
			/>
			<Toaster
				message={<Translate id={toasterMessageConfig.toasterMessage} />}
				open={toasterMessageConfig.showToaster}
				variant={toasterMessageConfig.toasterVariant}
				onClose={() => {
					setToasterMessageConfig({
						showToaster: false,
						toasterMessage: '',
						toasterVariant: '',
					})
				}}
			/>
			{isOpenSeeFileDialog.open && (
				<SeeFileAcknowledgmentFilesDialog
					{...isOpenSeeFileDialog}
					onClose={() => setIsOpenSeeFileDialog({ open: false, file: null })}
				/>
			)}
		</>
	)
}

const styles = (theme) => ({})

export default withauthenticatedLayout(withRouter(withStyles(styles, { withTheme: true })(Show)))
